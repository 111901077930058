.shelf-calculator {
    .inputs {
        width: 100%;

        .basic {
            float:left;
            width: 25%;
            box-sizing: border-box; 

            label {
                padding: 5px;
                margin-top: 10px;
                display: inline-block;
            }
        }

        .preview {
            display: inline-block;
            width: 75%;
            overflow: hidden;
            box-sizing: border-box;       
            height: 350px;
            margin-top: 40px;
            padding-left: 25px;
            position: relative;

            // iOS Safari
            -webkit-touch-callout: none;
            // Safari
            -webkit-user-select: none; 
            // Konqueror HTML 
            -khtml-user-select: none;
            // Old versions of Firefox
            -moz-user-select: none;
            // Internet Explorer/Edge
            -ms-user-select: none;
            // Non-prefixed version, currently supported by Chrome, Opera and Firefox
            user-select: none; 

            .switcher{
                font-size: 14px;
                font-weight: 400;
                border-radius: 4px;
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 10px;
                background-color: #fff;
                border: 1px solid #072747;
                cursor: pointer;
                display: inline-block;

                div{
                    display: inline-block;
                    padding: 7px 15px;

                    &.active {
                        background-color: #10467d;
                        color: #fff;
                    }
                }
            }

            img {
                max-width: 100%;
            }

            .virtualShelfContainer {
                width: 100%;
                height: 100%;

                canvas:focus {
                    outline: none;
                }
            }
        }

        .fields {
            width: 100%;
            color: black;
            background-color: #efefef;
            clear: both;
            display: inline-block;
            margin-top: 25px;
            width: 1200px;
            margin-left: -15px;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 10px;

            .shelf-width {
                width: 25%;
                display: inline-block;
                box-sizing: border-box; 
                padding: 10px;

                label {
                    padding: 5px;
                    display: inline-block;
                }
            }
        }
    }
    .details {
        p.price {
            font-weight: 700;
            font-size: 30px;
            margin-top: 50px;
            text-align: center;
        }
    }

    .contact {
        width: 800px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        .row {
            width: 100%;

            input {
                width: 100%;
            }

            .col-100,
            .col-50 {
                display: inline-block;
                padding-left: 10px;
                padding-right: 10px;
                box-sizing: border-box;
            }

            .col-100 {
                width: 100%;
            }

            .col-50 {
                width: 50%;
            }
        }

        input,
        textarea {
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-color: hsl(0,0%,100%);
            border-color: hsl(0,0%,80%);
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            min-height: 38px;
            outline: 0 !important;
            position: relative;
            -webkit-transition: all 100ms;
            transition: all 100ms;
            box-sizing: border-box;
            padding: 2px 8px;
            color: hsl(0,0%,20%);
            text-overflow: ellipsis;
            white-space: nowrap;
            top: 50%;
            font-size: 14px;
            line-height: 1.42857143;            
            font-family: 'Poppins', sans-serif;

            /* Chrome/Opera/Safari */
            &::-webkit-input-placeholder { 
                font-style: italic;
            }
            
            /* Firefox 19+ */
            &::-moz-placeholder { 
                font-style: italic;
            }

            /* IE 10+ */
            &::-ms-input-placeholder { 
                font-style: italic;
            }

            /* Firefox 18- */
            &::-moz-placeholder { 
                font-style: italic;
            }
            
            &.has-error {
                border-color: #f44336;
                color: #f44336;

                /* Chrome/Opera/Safari */
                &::-webkit-input-placeholder { 
                    color: #f44336;
                }
                
                /* Firefox 19+ */
                &::-moz-placeholder { 
                    color: #f44336;
                }

                /* IE 10+ */
                &::-ms-input-placeholder { 
                    color: #f44336;
                }

                /* Firefox 18- */
                &::-moz-placeholder { 
                    color: #f44336;
                }
            }
        }

        .checkbox {
            float:left;
            float: left;
            width: 20px;
            margin-right: 15px;
            margin-top: 8px;

            input {
                margin: 0;
                padding: 0;
                border: none;
                top: 0;
                left: 0;
                min-height: 0;
            }            
        }

        p.has-error {
            color: #f44336;
        }

        textarea {
            width: 100%;
            height: 100px;
            padding-top: 8px;
            max-width: 100%;
            resize: none;            
        }

        button {
            padding: 8px 20px;
            font-size: 16px;
            font-weight: 300;
            background-color: #10467d;
            color: #fff;
            border-radius: 4px;
            margin: 0 0 25px;
            border: none;
            cursor: pointer;
        }

        .success {
            padding: 20px;
            background-color: #c3deb7;
            border-radius: 3px;
            color: black;
            margin-top: 25px;
        }

        .loading {
            text-align: center;
            margin-top: 35px;
            
            .lds-ripple {
                display: inline-block;
                position: relative;
                width: 80px;
                height: 80px;
            }
            
            .lds-ripple div {
                position: absolute;
                border: 4px solid #036;
                opacity: 1;
                border-radius: 50%;
                animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            }
            
            .lds-ripple div:nth-child(2) {
                animation-delay: -0.5s;
            }
            
            @keyframes lds-ripple {
                0% {
                    top: 36px;
                    left: 36px;
                    width: 0;
                    height: 0;
                    opacity: 1;
                }

                100% {
                    top: 0px;
                    left: 0px;
                    width: 72px;
                    height: 72px;
                    opacity: 0;
                }
            }              
        }
        
        .failed {
            padding: 20px;
            background-color: #ff3535;
            border-radius: 3px;
            color: white;
            margin-top: 25px;

            a {  
                color: white;
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .shelf-calculator .inputs {
        .preview  img{
            max-width: unset;
        }

        .fields {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            box-sizing: border-box;
        }
    }
}

@media only screen and (max-width: 800px) {
    .shelf-calculator .inputs .fields .shelf-width {
        width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .shelf-calculator .inputs {
        .basic {
            width: 100%;
        }

        .preview {
            display: none;
        }
    }
}

@media only screen and (max-width: 430px) {
    .shelf-calculator .inputs {
        .fields .shelf-width {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 890px) {
    .shelf-calculator .contact {
        max-width: 100%;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 890px) {
    .shelf-calculator .contact .row .col-50 {
        width: 100%;

        &:first-child {
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 675px) {
    .shelf-calculator .details {
        text-align: center;

        .price {
            width: 335px;
            display: inline-block;
        }
    }
}